import React, { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Layout } from 'src/components/layout/Layout'
import { Router } from 'src/components/router/Router'
import { PasswordSecurity } from './components/PasswordSecurity'

export const App: React.FC = function App() {
  const [passwordValid, setPasswordValid] = useState(false)

  if (!passwordValid) {
    return <PasswordSecurity onValid={() => setPasswordValid(true)} />
  }

  return (
    <BrowserRouter>
      <Layout>
        <Router />
      </Layout>
    </BrowserRouter>
  )
}
