import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { routerLayout } from './Router.layout'

export const Router: React.FC = function Router() {
  const [routerItems, setRouterItems] = useState([] as React.ReactNode[])

  useEffect(() => {
    Promise.all(
      routerLayout.map(async (item) => {
        const imported = await import(`${__dirname}/../../pages/${item.componentPath}`)
        const pathParts = item.componentPath.split('/')
        const componentName = pathParts[pathParts.length - 1]
        const Component = imported[componentName]

        return <Route key={item.path} path={item.path} element={<Component />} />
      }),
    ).then((res) => {
      setRouterItems(res)
    })
  }, [])

  return <Routes>{routerItems}</Routes>
}
