import { Divider, Drawer, DrawerProps, List, ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { categories } from './Sidebar.layout'

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    color: '#ffffff',
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

export type SidebarProps = Omit<DrawerProps, 'classes'>

export const Sidebar: React.FC<SidebarProps> = function Sidebar(props) {
  const classes = useStyles()
  const active = 'authentication'

  return (
    <Drawer variant="permanent" {...props}>
      <List disablePadding>
        <ListItem className={clsx(classes.title, classes.item, classes.itemCategory)}>Ilis.be Admin</ListItem>
        {/* <ListItem className={clsx(classes.item, classes.itemCategory)} component="a" href="/">
          <ListItemIcon className={classes.itemIcon}>
            <Icon>home</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Project Overview
          </ListItemText>
        </ListItem> */}
        {categories.map((cat) => (
          <React.Fragment key={cat.id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {cat.label}
              </ListItemText>
            </ListItem>
            {cat.children?.map((childCat) => (
              <ListItem
                key={childCat.id}
                button
                className={clsx(classes.item, active === childCat.id && classes.itemActiveItem)}
                component="a"
                href={childCat.uri}
              >
                <ListItemIcon className={classes.itemIcon}>{childCat.icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childCat.label}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  )
}
