// import { IntlShape, useIntl } from 'react-intl'

// export function useTranslations(): [(id: string) => string, IntlShape] {
//   const intl = useIntl()

//   return [(id: string) => intl.formatMessage({ id }), intl]
// }

export function useTranslations(): [any, any] {
  return [
    () => {
      /* do nothing but do something in the future */
    },
    undefined,
  ]
}
