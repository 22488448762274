import { AppBar, Grid, Hidden, Icon, IconButton, Theme, Toolbar } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
}))

export interface HeaderProps {
  onDrawerToggle: () => void
}

export const Header: React.FC<HeaderProps> = function Header({ onDrawerToggle }) {
  const classes = useStyles()

  return (
    <AppBar color="primary" position="sticky" elevation={0}>
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton color="inherit" onClick={onDrawerToggle} className={classes.menuButton} size="large">
                <Icon>menu</Icon>
              </IconButton>
            </Grid>
            <Grid item>Ilis.be Admin</Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
