import Cookies from 'js-cookie'

export interface PaginatedResponse<T> {
  count: number
  items: T[]
}

export function getUrl(route: string) {
  return `${process.env.REACT_APP_API_URL}${route}`
}

function getPassword() {
  return Cookies.get('ilis-password') ?? 'ilis'
}

// eslint-disable-next-line no-undef
export function backendFetch(route: string, init: RequestInit = {}): Promise<Response> {
  const finalInit = {
    ...init,
    headers: {
      ...(init.headers ?? {}),
      Authorization: `Basic ${Buffer.from(`ilis:${getPassword()}`).toString('base64')}`,
    },
  }
  return fetch(getUrl(route), finalInit).then((resp) => {
    if (resp.status >= 400) {
      throw resp
    }
    return resp
  })
}
