import { useToggle } from 'src/hooks'
import { CssBaseline, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { theme } from 'src/styles'
import { Copyright } from './Copyright'
import { Header } from './Header'
import { Sidebar } from './Sidebar'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: 256,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
})

export interface LayoutProps {
  children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = function Layout(props) {
  const classes = useStyles()
  const [mobileOpen, toggleMobileOpen] = useToggle(false)
  const { children } = props

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="js">
          <Sidebar
            PaperProps={{ style: { width: 256 } }}
            variant="temporary"
            open={mobileOpen}
            onClose={toggleMobileOpen}
          />
        </Hidden>
        <Hidden mdDown implementation="css">
          <Sidebar PaperProps={{ style: { width: 256 } }} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={toggleMobileOpen} />
        <main>{children}</main>
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </div>
    </div>
  )
}
