import Cookies from 'js-cookie'
import { Box, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { tryAuth } from 'src/backend/auth'

export interface PasswordSecurityProps {
  onValid: () => void
}

export const PasswordSecurity: React.FC<PasswordSecurityProps> = function PasswordSecurity({ onValid }) {
  const [input, setInput] = useState(Cookies.get('ilis-password') ?? '')
  const [isLoading, setLoading] = useState(false)

  if (Cookies.get('validated') === 'true') {
    onValid()
    return null
  }

  const auth = () => {
    setLoading(true)
    tryAuth(input)
      .then((it) => {
        setLoading(false)
        if (it) {
          onValid()
          Cookies.set('ilis-password', input)
          Cookies.set('validated', 'true', { expires: new Date(new Date().getTime() + 1000 * 60 * 10) })
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    auth()
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <TextField type="password" placeholder="Mot de passe" onChange={(e) => setInput(e.target.value)} value={input} />
      <Button onClick={() => auth()} disabled={isLoading}>
        S&apos;authentifier
      </Button>
    </Box>
  )
}
