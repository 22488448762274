import { getUrl } from './backendFetch'

export async function tryAuth(password: string): Promise<boolean> {
  return fetch(getUrl('/auth/try'), {
    headers: {
      Authorization: `Basic ${Buffer.from(`ilis:${password}`).toString('base64')}`,
    },
  })
    .then((resp) => {
      if (resp.status >= 400) {
        throw resp
      }
      return resp.text()
    })
    .then((resp) => resp === 'OK')
}
