export interface RouterItem {
  path: string
  componentPath: string
}

export const routerLayout: RouterItem[] = [
  { path: '/', componentPath: 'Home' },
  { path: '/products', componentPath: 'products/Products' },
  { path: '/products/*', componentPath: 'products/ProductEditor' },
  { path: '/jobs', componentPath: 'jobs/Jobs' },
  { path: '/jobs/*', componentPath: 'jobs/JobEditor' },
  { path: '/files', componentPath: 'files/Files' },
  { path: '/files/*', componentPath: 'files/FileEditor' },
  { path: '/pages/home', componentPath: 'pages/Home' },
  { path: '/pages/album', componentPath: 'pages/Album' },
  { path: '/authentication', componentPath: 'authentication/Authentication' },
  { path: '*', componentPath: '_404' },
]
