import { Link, Typography } from '@mui/material'

export const Copyright: React.FC = function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright &copy;&nbsp;
      <Link color="inherit" href="https://ilis.be">
        ILIS.BE
      </Link>
      &nbsp;
      {new Date().getFullYear()}.
    </Typography>
  )
}
