import { Icon } from '@mui/material'

export interface Category {
  id: string
  label: string
  uri?: string
  icon?: React.ReactNode
  children?: Category[]
}

export const categories: Category[] = [
  {
    id: 'general',
    label: 'Général',
    children: [
      { id: 'products', label: 'Produits', uri: '/products', icon: <Icon>inventory</Icon> },
      { id: 'jobs', label: 'Jobs', uri: '/jobs', icon: <Icon>work</Icon> },
      // { id: 'files', label: 'Fichiers', uri: '/files', icon: <Icon>folder</Icon> },
    ],
  },
  {
    id: 'pages',
    label: 'Pages',
    children: [
      { id: 'home', label: 'Accueil', uri: '/pages/home', icon: <Icon>description</Icon> },
      { id: 'album', label: 'Album photo', uri: '/pages/album', icon: <Icon>description</Icon> },
    ],
  },
]
